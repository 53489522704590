@import '../theme-bootstrap';
@charset "UTF-8";

// Default Variables
$slick-arrow-color: $color-off-black;
$slick-arrow-hover: $color-off-black;
$slick-dot-color: $white;
$slick-dot-border-color: $color-off-black;
$slick-dot-color-active: $color-off-black;
$slick-dot-size: 16px;
// inverted colors
$slick-dot-color--inverted: $color-off-black;
$slick-dot-border-color--inverted: $white;
$slick-dot-color-active--inverted: $white;
//
// default slick
.slick {
  &-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  &-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    &:focus {
      outline: none;
    }
    &.dragging {
      cursor: hand;
    }
  }
  &-track,
  &-list {
    @include transform(translate3d(0, 0, 0));
  }
  &-track {
    position: relative;
    #{$ldirection}: 0;
    top: 0;
    display: block;
    &::before,
    &::after {
      content: '';
      display: table;
    }
    &::after {
      clear: both;
    }
    .slick-loading & {
      visibility: hidden;
    }
  }
  &-slide {
    float: $ldirection;
    height: 100%;
    min-height: 1px;
    display: none;
    outline: none;
    [dir='rtl'] & {
      float: $rdirection;
    }
    &.slick-loading img {
      display: none;
    }
    &.dragging img {
      pointer-events: none;
    }
    .slick-initialized & {
      display: block;
      position: relative;
    }
    .slick-loading & {
      visibility: hidden;
    }
    .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
  }
}

// overriding for SBX with container specificity
.carousel-controls {
  position: absolute;
  height: 0;
  line-height: 0;
  width: 100%;
  display: block;
  top: 50%;
  //  @todo pull outside container specificity
  .slick-prev,
  .slick-next {
    @include font-smoothing;
    @include appearance(none);
    background: transparent;
    border: none;
    color: $slick-arrow-color;
    cursor: pointer;
    font-size: 0;
    height: 50px;
    line-height: 50px;
    width: 50px;
    padding: 0;
    position: absolute;
    z-index: 2;
    &:hover {
      color: $slick-arrow-hover;
    }
    &:focus {
      outline: none;
    }
    // this is for the icons
    &::before {
      content: '';
      background-color: $color-off-black;
      width: 50px;
      height: 50px;
      font-size: 50px;
      line-height: get-line-height(50px, 50px);
    }
    &.slick-disabled {
      cursor: default;
      opacity: 0.4;
      &:hover {
        opacity: 0.4;
      }
    }
  }
  .slick-prev {
    #{$ldirection}: 10px;
    position: absolute;
    &::before {
      @include svg-icon-inline-mask('caret--left');
      position: absolute;
      #{$rdirection}: auto;
      #{$ldirection}: 0;
    }
  }
  //  we need to override positioning for 'next' to account for text-replace
  .slick-next {
    #{$rdirection}: 10px;
    position: absolute;
    &::before {
      @include svg-icon-inline-mask('caret--right');
      position: absolute;
      #{$rdirection}: 0;
    }
  }
}

// / DOTS for carousel
.carousel-dots {
  width: 100%;
  height: $slick-dot-size;
  margin-bottom: $slick-dot-size / 2;
  position: relative;
  @include breakpoint($landscape-up) {
    height: 40px;
    margin-top: -40px;
    margin-bottom: 0;
    &.story-carousel-dots {
      margin-top: 0;
    }
  }
  &--basic {
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
}
//Dots
.slick-dots {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 10px;
  li {
    position: relative;
    display: inline-block;
    height: $slick-dot-size;
    width: $slick-dot-size;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: $slick-dot-size;
      width: $slick-dot-size;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      min-width: $slick-dot-size;
      &:hover,
      &:focus,
      &:active {
        outline: none;
        &::before {
          opacity: 1;
        }
      }
      &::before {
        @include circle($slick-dot-size, $slick-dot-color);
        background: transparent;
        border: $slick-dot-border-color solid 2px;
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
        content: '';
        width: $slick-dot-size;
        height: $slick-dot-size;
        //font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: $slick-dot-size;
        text-align: center;
        color: $slick-dot-color;
        opacity: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button::before {
      background: $slick-dot-color-active;
      color: $slick-dot-color-active;
      opacity: 1;
    }
    .elc-full-size-sample & {
      width: auto;
    }
  }
}
